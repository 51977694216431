<template>
	<Dialog v-model:visible="mostrarDlg" :modal="true" :closable="false">
		<!-- <template #header>
			<i class="pi pi-envelope mr-2" style="fontSize: 2rem"></i>
			<p class="p-m-0">¿Está seguro que desea enviar el documento a los siguientes destinatarios?</p>
		</template> -->
		<!-- <DataTable :value="emails" v-model:selection="selEmails" selectionMode="multiple" dataKey="email">
			<Column selectionMode="multiple"/>
			<Column field="email">
				<template #header>						
					<p class="p-m-1">¿Está seguro que desea enviar el documento a los siguientes destinatarios?</p>	
					<i class="pi pi-envelope p-ml-2" style="fontSize: 2rem"></i>		
				</template>			
			</Column>
		</DataTable>		 -->
		<Fieldset :toggleable="false">
			<template #legend>				
				<p class="p-m-0"><i class="pi pi-envelope p-mr-2"/>{{ mensajeStr }}</p>
			</template>
			<div v-for="(result,i) in emails" :key="i" class="p-m-2 p-fluid p-inputgroup">
				<!-- <InputSwitch v-model="a.asignado" @change="setPermiso($event, a)"/> -->
				<Checkbox v-model="result.asignado" :binary="true"/>
				<div class="p-my-2 p-ml-3">{{result.email}}</div>                    
			</div>		
		</Fieldset>		
		<!-- <div v-for="(result,i) in emails" :key="i">
			<div>
				<Checkbox v-model="checked" :binary="true" />
				<div class="font-semibold"> [{{ result.email }}] </div>
			</div>
		</div> -->
		<template #footer>
			<Button label="No" icon="pi pi-times" class="p-button-text" @click="cancelar"/>
			<Button label="Si" icon="pi pi-check" :disabled="!destinatariosSelected" @click="confirmar" autofocus />
		</template>
	</Dialog>
</template>

<script> 	
	export default {
		name: 'ConfirmacionEnvioEmails',
		props: {
			mensaje: {
				type: String,
				default: "¿Desea enviar el documento a estos destinatarios?"
			},
			mostrar: {type: Boolean, required: true},
			destinatarios: {type: Array, required: true }
		},
		data() {
				return {
					mensajeStr : this.mensaje,
					emails: this.destinatarios?this.destinatarios.map(d => {d.asignado = true; return d;}): [],
				}
		},
		computed: {
			mostrarDlg() {
				return this.mostrar;
			},
			destinatariosSelected() {
				if(this.destinatarios) {
				 return this.destinatarios.find(d=>d.asignado);
				} else {
					return false;
				}				
			}		
		},
		methods: {
			cancelar() {
				this.$emit('cancelar');				
			},
			confirmar() {
				var sels = this.destinatarios.filter(d => { return d.asignado; });
				var emails = sels.map(d => { return {email: d.email }});

				this.$emit('confirmacion', emails);
			}
		}
	}
</script>

<style scoped>

</style>
